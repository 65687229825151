import { MTLB_TYPE_COMPLAINT } from "../../../../auto/js/metadata/MtlbType";
import { INCIDENT_CATEGORY_GOVERNMENTAL_CONCERN, IncidentCategory } from "../../../../auto/js/metadata/IncidentCategory";
import { COMPLAINANT_CONTACT_PREFERENCES_EMAIL, ComplainantContactPreferences } from "../../../../auto/js/metadata/ComplainantContactPreferences";
import { buildEmptyObject } from "../../../../auto/js/widgets/FormComponent";

const incidentCategory = {
	1: "CONSUMER_RIGHTS",
	2: "ENVIRONMENTAL_CONCERNS",
	3: "PUBLIC_SAFETY",
	4: "GOVERNMENTAL_CONCERN"
}

const complainantContactPreferences = {
	1: "EMAIL",
	2: "PHONE"
}

export const complaintMtlbFields = [
	{name: "complainantFirstName", type: "text", x: 1, y: 3, layout: "col-md-12" },
	{name: "complainantLastName", type: "text", x:1, y:4, layout:"col-md-6"},
	{name: "complainantIdCardNumber", type: "number", x:2, y:4, layout:"col-md-6"},
	{name: "complainantEmailAddress", type: "text", x:1, y: 5, layout:"col-md-6"},
	{name: "complainantPhoneNumber", type: "text", x:1, y: 5, layout:"col-md-6"},
	{name: "complainantAddress", type: "text", x:2, y: 6, layout:"col-md-6"},
	{name: "complainantContactPreferences", type: "select", x:1, y: 7, layout:"col-md-6", metadata: () => complainantContactPreferences},
	{name: "incidentCategory", type: "select", x:1, y: 8, layout:"col-md-6",  metadata: () => incidentCategory},
    {name: "jurisdiction", type:"text", x:1, y:17, layout:"col-md-6"},
    {name: "subjectOfComplaint", type:"text", x:1, y:17, layout:"col-md-6"},
    {name: "subjectAddress", type:"text", x:1, y:17, layout:"col-md-6"},
    {name: "description", type:"text", x:1, y:17, layout:"col-md-6"},
];

export const form2dto = (formData, dto) => {
	if (dto.mtlbType === null || !dto.mtlbType)
		dto.mtlbType = MTLB_TYPE_COMPLAINT;
	if (dto.incidentCategory === null || !dto.incidentCategory)
		dto.incidentCategory = INCIDENT_CATEGORY_GOVERNMENTAL_CONCERN;
	else
		dto.incidentCategory = incidentCategory[dto.incidentCategory];
	if (dto.complainantContactPreferences === null || !dto.complainantContactPreferences)
		dto.complainantContactPreferences = COMPLAINANT_CONTACT_PREFERENCES_EMAIL;
	else
		dto.complainantContactPreferences = complainantContactPreferences[dto.complainantContactPreferences]
	if (dto.complainantIdCardNumber === null || ! dto.complainantIdCardNumber)
		dto.complainantIdCardNumber = 0;
}

export const dto2form = (dto) => {
	let form = dto;
  return form;
}

export const buildComplaintMtlbFormEmptyObject  = () => {
	return buildEmptyObject(complaintMtlbFields);
}

export const newComplaintMtlbFormForm2Dto = (form, dto) => {
	
}