import React from "react";
import { getServiceUri } from "../../../../auto/js/metadata";
import { COMPLAINT_MTLB_ORDER_BY_SENDER_FIRST_NAME } from "../../../../auto/js/metadata/ComplaintMtlbOrderBy";
import { MTLB_TYPE_COMPLAINT } from "../../../../auto/js/metadata/MtlbType";
import { rest } from "../../../../auto/js/services";

export const complaintMtlbListColumns = [
    { title: "Complainant First Name", field: "complainantFirstName" },
    { title: "Complainant Last Name", field: "complainantLastName" },
    { title: "Complainant IdCardNumber", field: "complainantIdCardNumber" },
    { title: "Complainant EmailAddress", field: "complainantEmailAddress" },
    { title: "Complainant PhoneNumber", field: "complainantPhoneNumber" },
    { title: "Complainant Address", field: "complainantAddress" },
    { title: "Complainant Contact Preferences", field: "complainantContactPreferences" },
    { title: "Incident Category", field: "incidentCategory" },
    { title: "Incident Date", field: "incidentDate" },
    { title: "Incident Time", field: "incidentTime" },
    { title: "Jurisdiction", field: "jurisdiction" },
    { title: "Subject Of Complaint", field: "subjectOfComplaint" },
    { title: "Subject Address", field: "subjectAddress" },
    { title: "Description", field: "description" },
    // { name: "evidence", field: "evidence" }
];

export const transformAdvancedSearchData = (formData) => {
    const columns = [];

    const addColumn = (name, type, value, condition) => {
        columns.push({
            name,
            type,
            [type === 'date' ? 'dateValue' : type === 'boolean' ? 'booleanValue' : (type === 'text' ? 'textValue' : 'longValue')]: value,
            searchType: condition ? condition : null,
        });
    };
    (formData.complainantFirstName !== "") && addColumn('complainantFirstName', 'text', formData.complainantFirstName, formData.complainantFirstNameCondition);
    (formData.complainantLastName !== "") && addColumn('complainantLastName', 'text', formData.complainantLastName, formData.complainantLastNameCondition);
    (formData.complainantIdCardNumber !== "") && addColumn('complainantIdCardNumber', 'text', formData.complainantIdCardNumber, formData.complainantIdCardNumberCondition);
    (formData.complainantEmailAddress !== "") && addColumn('complainantEmailAddress', 'text', formData.complainantEmailAddress, formData.complainantEmailAddressCondition);
    (formData.complainantPhoneNumber !== "") && addColumn('complainantPhoneNumber', 'text', formData.complainantPhoneNumber, formData.complainantPhoneNumberCondition);
    (formData.complainantAddress !== "") && addColumn('complainantAddress', 'text', formData.complainantAddress, formData.complainantAddressCondition);
    (formData.complainantContactPreferences !== "") && addColumn('complainantContactPreferences', 'text', formData.complainantContactPreferences, formData.complainantContactPreferencesCondition);
    (formData.incidentCategory !== "") && addColumn('incidentCategory', 'text', formData.incidentCategory, formData.incidentCategoryCondition);
    (formData.incidentDate !== "") && addColumn('incidentDate', 'date', formData.incidentDate, formData.incidentDateCondition);
    (formData.incidentTime !== "") && addColumn('incidentTime', 'time', formData.incidentTime, formData.incidentTimeCondition);
    (formData.jurisdiction !== "") && addColumn('jurisdiction', 'text', formData.jurisdiction, formData.jurisdictionCondition);
    (formData.subjectOfComplaint !== "") && addColumn('subjectOfComplaint', 'text', formData.subjectOfComplaint, formData.subjectOfComplaintCondition);
    (formData.subjectAddress !== "") && addColumn('subjectAddress', 'text', formData.subjectAddress, formData.subjectAddressCondition);

    addColumn('mtlbType', 'number', 4, null);
    addColumn('draft', 'boolean', false, null);

    return { columns };
};

export const advancedSearchfields = [
    { name: "description", type: "text", x: 1, y: 2, layout: "col-md-6" },
    {
        name: "descriptionCondition", options: [
            { "name": "fuzzy", "label": "Fuzzy" },
            { "name": "exact", "label": "Exact" },
            { "name": "startsWith", "label": "Startswith" },
        ], type: "radio", x: 2, y: 2, layout: "col-md-6 pt-5", defaultValue: "fuzzy"
    },
    { name: "subjectAddress", type: "text", x: 1, y: 2, layout: "col-md-6" },
    {
        name: "subjectAddressCondition", options: [
            { "name": "fuzzy", "label": "Fuzzy" },
            { "name": "exact", "label": "Exact" },
            { "name": "startsWith", "label": "Startswith" },
        ], type: "radio", x: 2, y: 2, layout: "col-md-6 pt-5", defaultValue: "fuzzy"
    },
    { name: "subjectOfComplaint", type: "text", x: 1, y: 2, layout: "col-md-6" },
    {
        name: "subjectOfComplaintCondition", options: [
            { "name": "fuzzy", "label": "Fuzzy" },
            { "name": "exact", "label": "Exact" },
            { "name": "startsWith", "label": "Startswith" },
        ], type: "radio", x: 2, y: 2, layout: "col-md-6 pt-5", defaultValue: "fuzzy"
    },
    { name: "incidentTime", type: "text", x: 1, y: 2, layout: "col-md-6" },
    {
        name: "incidentTimeCondition", options: [
            { "name": "fuzzy", "label": "Fuzzy" },
            { "name": "exact", "label": "Exact" },
            { "name": "startsWith", "label": "Startswith" },
        ], type: "radio", x: 2, y: 2, layout: "col-md-6 pt-5", defaultValue: "fuzzy"
    },
    { name: "incidentDate", type: "text", x: 1, y: 2, layout: "col-md-6" },
    {
        name: "incidentDateCondition", options: [
            { "name": "fuzzy", "label": "Fuzzy" },
            { "name": "exact", "label": "Exact" },
            { "name": "startsWith", "label": "Startswith" },
        ], type: "radio", x: 2, y: 2, layout: "col-md-6 pt-5", defaultValue: "fuzzy"
    },
    { name: "incidentCategory", type: "text", x: 1, y: 2, layout: "col-md-6" },
    {
        name: "incidentCategoryCondition", options: [
            { "name": "fuzzy", "label": "Fuzzy" },
            { "name": "exact", "label": "Exact" },
            { "name": "startsWith", "label": "Startswith" },
        ], type: "radio", x: 2, y: 2, layout: "col-md-6 pt-5", defaultValue: "fuzzy"
    },
    { name: "complainantContactPreferences", type: "text", x: 1, y: 2, layout: "col-md-6" },
    {
        name: "complainantContactPreferencesCondition", options: [
            { "name": "fuzzy", "label": "Fuzzy" },
            { "name": "exact", "label": "Exact" },
            { "name": "startsWith", "label": "Startswith" },
        ], type: "radio", x: 2, y: 2, layout: "col-md-6 pt-5", defaultValue: "fuzzy"
    },
    { name: "complainantAddress", type: "text", x: 1, y: 2, layout: "col-md-6" },
    {
        name: "complainantAddressCondition", options: [
            { "name": "fuzzy", "label": "Fuzzy" },
            { "name": "exact", "label": "Exact" },
            { "name": "startsWith", "label": "Startswith" },
        ], type: "radio", x: 2, y: 2, layout: "col-md-6 pt-5", defaultValue: "fuzzy"
    },
    { name: "complainantPhoneNumber", type: "text", x: 1, y: 2, layout: "col-md-6" },
    {
        name: "complainantPhoneNumberCondition", options: [
            { "name": "fuzzy", "label": "Fuzzy" },
            { "name": "exact", "label": "Exact" },
            { "name": "startsWith", "label": "Startswith" },
        ], type: "radio", x: 2, y: 2, layout: "col-md-6 pt-5", defaultValue: "fuzzy"
    },
    { name: "complainantFirstName", type: "text", x: 1, y: 2, layout: "col-md-6" },
    {
        name: "complainantFirstNameCondition", options: [
            { "name": "fuzzy", "label": "Fuzzy" },
            { "name": "exact", "label": "Exact" },
            { "name": "startsWith", "label": "Startswith" },
        ], type: "radio", x: 2, y: 2, layout: "col-md-6 pt-5", defaultValue: "fuzzy"
    },

    { name: "complainantLastName", type: "text", x: 1, y: 3, layout: "col-md-6" },
    {
        name: "complainantLastNameCondition", options: [
            { "name": "fuzzy", "label": "Fuzzy" },
            { "name": "exact", "label": "Exact" },
            { "name": "startsWith", "label": "Startswith" },
        ], type: "radio", x: 2, y: 3, layout: "col-md-6 pt-5", defaultValue: "fuzzy"
    },
    { name: "complainantIdCardNumber", type: "text", x: 1, y: 4, layout: "col-md-6" },
    {
        name: "complainantIdCardNumberCondition", options: [
            { "name": "fuzzy", "label": "Fuzzy" },
            { "name": "exact", "label": "Exact" },
            { "name": "startsWith", "label": "Startswith" },
        ], type: "radio", x: 2, y: 4, layout: "col-md-6 pt-5", defaultValue: "fuzzy"
    },
    { name: "complainantEmailAddress", type: "text", x: 1, y: 4, layout: "col-md-6" },
    {
        name: "complainantEmailAddressCondition", options: [
            { "name": "fuzzy", "label": "Fuzzy" },
            { "name": "exact", "label": "Exact" },
            { "name": "startsWith", "label": "Startswith" },
        ], type: "radio", x: 2, y: 4, layout: "col-md-6 pt-5", defaultValue: "fuzzy"
    },
];

export const getData = async (filter) => {
    let searchEndpoint = (filter.query) ? "complaint-mtlb/advanced-search" : "complaint-mtlb/search-document/";
    return await rest.request(getServiceUri() + searchEndpoint, "POST", filter);
}

export const countData = async (filter) => {
    let countEndpoint = (filter.query) ? "complaint-mtlb/count/advanced-search" : "complaint-mtlb/count-document/";
    return await rest.request(getServiceUri() + countEndpoint, "POST", filter);
}

export const getOrderBy = () => {
    return COMPLAINT_MTLB_ORDER_BY_SENDER_FIRST_NAME
}

export const getMtlbTypeList = () => {
    return [MTLB_TYPE_COMPLAINT]
}

export const filterData = (defaultRows) => {
    return defaultRows;
}

export const getErrorList = () => {
    let list = [];
    for (let i = 1; i < 18; i++) {
        let code = (i < 10) ? "E" + "0" + i : "E" + i
        list.push(code);
    }
    list.push("E20")
    list.push("E21")
    for (let i = 28; i < 32; i++) {
        let code = (i < 10) ? "E" + "0" + i : "E" + i
        list.push(code);
    }
    return list;
}

