/* import {displayNewBirthRegistrationForm} from '../../../auto/js/forms/birthRegistration/NewBirthRegistrationForm'
import {displayNewMarriageRegistrationForm} from '../../../auto/js/forms/marriageRegistration/NewMarriageRegistrationForm'
import {displayNewDivorceRegistrationForm} from '../../../auto/js/forms/divorceRegistration/NewDivorceRegistrationForm'
import {displayNewDeathRegistrationForm} from '../../../auto/js/forms/deathRegistration/NewDeathRegistrationForm'
import {displayCivilRecordsList} from '../../../auto/js/lists/CivilRecordList'
import {displayNewOccupationForm} from '../../../auto/js/forms/metadata/OccupationForm' */
import {displayNewTranslationsForm} from '../../../auto/js/forms/metadata/TranslationsForm'
/* import {displayNewCountryForm} from '../../../auto/js/forms/metadata/CountryForm'
import {displayCountriesList} from '../../../auto/js/lists/metadata/CountriesList'
import {displayOccupationList} from '../../../auto/js/lists/metadata/OccupationList' */
import {displayTranslationsList} from '../../../auto/js/lists/metadata/TranslationsList'
/* import {displayDeathRecordList} from '../../../auto/js/lists/DeathRecordList'
import {displayUnionRecordList} from '../../../auto/js/lists/UnionRecordList' */
import {whoami} from '../../../auto/js/users/UserInfo';
import { RIBBON_MENU } from '../../../auto/js/metadata/MenuType';
/* import {displayLocationsList} from "../../../auto/js/lists/metadata/LocationsList";
import {displayNewLocationForm} from "../../../auto/js/forms/metadata/LocationsForm";
import { displayDigitalizationApplicationList } from '../lists/digitalization/DigitalizationApplicationList'
import { displayBirthRegistrationList } from '../../../auto/js/lists/birthRegistration/BirthRegistrationList'
import { displayAmendmentApplicationList } from '../../../auto/js/lists/amendmentApplication/AmendmentApplicationList'
import { displayMarriageRegistrationList } from '../../../auto/js/lists/marriageRegistration/MarriageRegistrationList'
import { displayDivorceRegistrationList } from '../../../auto/js/lists/divorceRegistration/DivorceRegistrationList'
import { displayDeathRegistrationList } from '../../../auto/js/lists/deathRegistration/DeathRegistrationList' */
import { displayNewComplaintMtlbForm } from '../../../auto/js/forms/complaintMtlb/NewComplaintMtlbForm'
import { displayComplaintMtlbList } from '../../../auto/js/lists/complaintMtlb/ComplaintMtlbList'

export const menu = () => {
    return {
        ...((whoami().roles.includes('ALL')|| whoami().roles.includes('REGISTRAR')) && {
			"complaints-record": {
				submenu: {
					"complaint-applications": {
						options: {
							new: { label: "New", do: (gui) => displayNewComplaintMtlbForm(gui) },
							// temporaryCertificate: { label: "Temporary Certificate", do: (gui) => displayTemporaryCertificateWorkflow(gui) },
							applications: { label: "Applications", do: (gui) => displayComplaintMtlbList(gui)}
						},
						label: "Complaints Applications"
					}
				},
				label: "Complaints"
			}
        }),
        /* ...((whoami().roles.includes('ALL') || whoami().roles.includes('DIGITALIZER') || whoami().roles.includes('REGISTRAR')) && {
            "digitalization": {
                submenu: {
                    "digitalization": {
                        options: {
                            "digitalization-forms": {label: "Digitalization Forms", do: displayDigitalizationApplicationList}
                        }
                    }
                },
                label: "Digitalization"
            }
        }), */
        /* ...((whoami().roles.includes('ALL')|| whoami().roles.includes('REGISTRAR')) && {
            "civil-records": {
                submenu: {
                    "birth-registration": {
                        options: {
                            new: {label: "New", do: () => displayNewBirthRegistrationForm()},
                            applications: { label: "Applications", do: displayBirthRegistrationList}
                        },
                        label: "Birth Registration"
                    }
                },
                label: "Birth"
            },
            "amendment": {
				submenu: {
					"amendment-application": {
						options: {
							applications: { label: "Applications", do: displayAmendmentApplicationList}
						},
						label: "Amendment Application"
					}
				},
				label: "Amendment"
			},
            "marriage-records": {
                submenu: {
                    "marriage-registration": {
                        options: {
                            new: { label: "New", do: () => displayNewMarriageRegistrationForm() },
                            applications: { label: "Applications", do: displayMarriageRegistrationList}
                        },
                        label: "Marriage Registration"
                    },
                    "divorce-registration": {
                        options: {
                            new: { label: "New", do: () => displayNewDivorceRegistrationForm() },
                            applications: { label: "Applications", do: displayDivorceRegistrationList}
                        },
                        label: "Divorce Registration"
                    },
                    other: {
                        options: {
                            search: {label: "Records", do: displayUnionRecordList}
                        }
                    }
                },
                label: "Marriage & Divorces"
            },
            "death-records": {
                submenu: {
                    "death-registration": {
                        options: {
                            new: { label: "New", do: () => displayNewDeathRegistrationForm() },
                            applications: { label: "Applications", do: displayDeathRegistrationList}
                        },
                        label: "Death Registration"
                    },
                    other: {
                        options: {
                            search: {label: "Death Records", do: displayDeathRecordList}
                        }
                    }
                },
                label: "Death"
            },
        }), */
        ...((whoami().roles.includes('ALL') || whoami().roles.includes('MAINTENANCE')) && {
            "crvs-admin": {
                submenu: {
                    /* "occupations": {
                        options: {
                            list: {label: "Occupations", do: displayOccupationList},
                            new: {label: "New", do: displayNewOccupationForm},
                        },
                        label: "Occupations"
                    },
                    "countries": {
                        options: {
                            list: {label: "Countries", do: displayCountriesList},
                            new: {label: "New", do: displayNewCountryForm},
                        },
                        label: "Countries"
                    }, */
                    "translations": {
                        options: {
                            list: {label: "Translations", do: displayTranslationsList},
                            new: {label: "New", do: displayNewTranslationsForm},
                        },
                        label: "Translations"
                    },
                },
                label: "Configuration"
            }
        }),
        /* ...((whoami().roles.includes('ALL') || whoami().roles.includes('MAINTENANCE')) && {
            "geography": {
                submenu: {
                    "locations": {
                        options: {
                            list: {label: "Geo Data", do: displayLocationsList},
                            new: {label: "New", do: displayNewLocationForm},
                        },
                        label: "Geo Data"
                    },
                },
                label: "Geography"
            }

        }) */
    }
}
export const menuType = RIBBON_MENU;
